/* You can add global styles to this file, and also import other style files */
@use "@ids/styles/scss/styles" as *;
@use "@ids/styles/scss/fonts/itauIcon.scss" as *;

/* Tenants Themes */
@use "./tenants/finpec.scss" as *;
@use "./tenants/2w.scss" as *;
@use "./tenants/porto.scss" as *;

@font-face {
	font-display: swap;
	font-family: "Password";
	src: url("../assets/password.woff2") format("woff2");
	src: url("../assets/password.woff") format("woff");
	src: url("../assets/password.ttf") format("ttf");
}

body,
html {
	font-family: "Itau Text", sans-serif;
	height: 100vh;
	overflow-x: hidden;
	background-color: var(--background);
}

::-webkit-scrollbar {
	display: none;
}
