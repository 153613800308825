// SCSS não aceita alphanumerics com nome de classes
// Esse nome de classe deve ser o mesmo definido no theme.service.ts
.doisw {
	// Cor de fundo das telas
	--background: #fff !important;

	// Cor do header;
	--ids_color_bg_brand_primary: #73ff9f !important;

	// Cor dos elementos: botões, inputs, ícones...
	--ids_color_action_primary_base: #4c2275 !important;

	// Cor do texto dos botões.
	--ids_color_action_primary_contrast: #fff !important;

	// Cor do hover em botões;
	--ids_color_action_primary_variant: #ededed !important;

	// Cor de links
	--ids_color_action_secondary_base: #4c2275 !important;

	// Cor dos textos em tela
	--ids_color_text_body_01: #333 !important;

	// Cor de texto durante digitação.
	--ids_color_action_neutral_variant: #333 !important;

	// Cor de texto em labels
	--ids_color_action_neutral_base: #333 !important;

	// Cor texto e elementos que apresente erro
	--ids_color_feedback_error: #b71c1c !important;

	// Cor de texto e elementos que apresente sucesso
	--ids_color_feedback_success: #007830 !important;
}
